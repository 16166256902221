import * as Sentry from '@sentry/remix'
import type { RemixOptions } from '@sentry/remix/build/types/utils/remixOptions'
import type { Extras } from '@sentry/types'
import type { UserWithoutToken } from '~/modules/user'

import { NODE_ENV, SENTRY_DSN, SENTRY_ENABLED, isDev } from '~/utils/env'

export const ignoreErrors = [
  /The user aborted a request/i,
  /can't access dead object/i,
  /The operation was aborted/i,
  /Fetch is aborted/i,
  /Not Found/i,
]

export const ignoreStatusCodes = [301, 302, 404, 410, 409]

export const isSentryEnabled = !isDev() && SENTRY_ENABLED === 'true' && SENTRY_DSN

export function initSentry(opts: RemixOptions = {}) {
  return isSentryEnabled
    ? Sentry.init({
        dsn: SENTRY_DSN,
        tracesSampleRate: 1.0,
        environment: NODE_ENV || 'development',
        ...opts,
        ignoreErrors,
      })
    : () => null
}

type SentryHelperProps = {
  extras?: Extras
  skipLogging?: boolean
}

export function sentryMessage({
  message,
  extras,
  skipLogging = false,
}: SentryHelperProps & { message: string }) {
  if (isSentryEnabled) {
    Sentry.withScope((scope) => {
      if (extras) {
        scope.setExtras(extras)
      }
      Sentry.captureMessage(message)
    })
  }

  if (skipLogging !== true) {
    console.log(message)
  }
}

export function sentryException({
  error,
  extras,
  skipLogging = false,
}: SentryHelperProps & { error: unknown }) {
  if (isSentryEnabled) {
    Sentry.withScope((scope) => {
      if (extras) {
        scope.setExtras(extras)
      }
      Sentry.captureException(error)
    })
  }
  if (skipLogging !== true) {
    console.error(error)
  }
}

export function sentrySetUser(user: UserWithoutToken) {
  if (isSentryEnabled) {
    Sentry.setUser({
      username: user.displayName,
      id: String(user.employeeId),
      email: user.email,
      isImpersonated: user?.isImpersonatedUser ? 'yes' : 'no',
    })
  }
}
